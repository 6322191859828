<template>
  <div class="m-t">
    <e-row mr>
      <e-col>
        <h5>
          Inscritos
        </h5>
      </e-col>
    </e-row>

    <div class="filters row font-11 justify-between">
      <div class="col text-grey-7 flex items-center" style="max-width: 100px; line-height: 20px">
        <!--{{table.serverPagination.rowsNumber}} lote{{table.serverPagination.rowsNumber > 1 ? 's' : ''}}-->
        {{ table.serverPagination.rowsNumber|number }} inscrito{{ table.serverPagination.rowsNumber > 1 ? 's' : '' }}
        <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/>
      </div>
      <div class="col m-l-lg" style="line-height: 20px; max-width: 500px">
        <div class="row">
          <div class="col flex flex-center items-center">
            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.enviados">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Enviados</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.naoEnviados">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Não enviados</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.desinscritos">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Desinscritos</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.lidos">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Lidos</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.clicados">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Clicados</span>
              </label>
            </erp-checkbox>

          </div>
        </div>
      </div>
      <div class="col" style="max-width: 240px">
        <erp-s-field
            class="font-10"
            view="lr"
            label="Buscar:"
        >
          <helper-input-btn>
            <erp-input @keydown.enter="load" simple-border v-model="table.filters.pesquisa"/>
            <a class="m-l-xs text-black" @click="load">IR</a>
          </helper-input-btn>
        </erp-s-field>
      </div>
      <div class="col m-l m-b-n" style="max-width: 250px; text-align: right">
        <u-btn @click="previewFull" no-caps size="sm" :color="previewFullActive ? 'white' : 'blue'" :class="previewFullActive ? 'text-grey' : 'text-white'" class="m-r-xs">
          <i class="fa fa-eye m-r-xs"></i> <span v-if="previewFullActive">Listar Inscritos</span><span v-else>Pré-visualizar</span>
        </u-btn>
        <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>
      </div>
    </div>
    <div class="erp-list no-pad" v-if="!previewFullActive">
      <u-table
          ref="table"
          color="primary"
          :data="filteredServerData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <!--          <u-th auto-width>
                      <u-checkbox
                          v-model="props.selected"
                          :indeterminate="props.partialSelected"
                          size="sm"
                      />
                    </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props"
              :props="props">
          <!--          <u-td auto-width>
                      <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                    </u-td>-->
          <u-td key="nome" :props="props">
            <div>{{ props.row.pessoa.name }}</div>
          </u-td>
          <u-td class="td-limit l-200" key="contato" :props="props">
            {{ props.row.mensagens ? props.row.mensagens.map(m => m.destinatario).join(', ') : '-' }}
          </u-td>
          <u-td key="envios" :props="props">
            <u-chip dense square :color="Number(props.row.cacheEnviados) > 0 ? 'blue' : 'red'">
              {{ props.row.cacheEnviados|number }}
            </u-chip>
            <span v-if="props.row.status === 99" class="text-negative m-l-sm">Falha</span>
          </u-td>
          <u-td key="lido" :props="props">
            <u-chip dense square :color="Number(props.row.cacheAbertos) > 0 ? 'green' : 'blue-grey-2'">
              {{ props.row.cacheAbertos|number }}
            </u-chip>
          </u-td>
          <u-td key="cliques" :props="props">
            <u-chip dense square :color="Number(props.row.cacheCliques) > 0 ? 'purple' : 'blue-grey-2'">
              {{ props.row.cacheCliques|number }}
            </u-chip>
          </u-td>
          <u-td key="desinscrito" :props="props">
            <u-chip dense square :color="props.row.desinscrito? 'red' : ''">{{
                props.row.desinscrito ? 'Sim' : ''
              }}
            </u-chip>
          </u-td>
          <u-td class="text-uppercase" key="cobranca" :props="props">
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <div class="flex items-center justify-center menu-list-def">
              <a @click="enviarCampanhaInscrito(props.row)" v-if="!enviando.includes(props.row.id)">
                <i class="fa fa-fw fa-duotone fa-send"></i>
                <u-tooltip :offset="[10,10]">Enviar manualmente</u-tooltip>
              </a>
              <a v-else>
                <i class="fa fa-fw fa-spin fa-spinner"></i>
              </a>
<!--              <a @click="preview(props.row)">
                <i class="fa fa-fw fa-duotone fa-eye"></i>
                <u-tooltip :offset="[10,10]">Pré-visualizar</u-tooltip>
              </a>-->
              <a @click="remover(props.row)">
                <i class="fa fa-fw fa-duotone fa-trash"></i>
                <u-tooltip :offset="[10,10]">Remover inscrição</u-tooltip>
              </a>
            </div>
          </u-td>
        </u-tr>
      </u-table>
    </div>
    <div class="erp-list bg-white wrapper" style="z-index: 100; position: relative; border-radius: 10px" v-else>
      <iframe v-if="!loadingPreview" ref="frame" style="width: 100%; height: 100%; min-height: 1000px; border: #CCCCCC 1px solid; border-radius: 2px" :src="srcPreview"></iframe>
      <div class="flex center justify-center items-center" v-else>
        <sl-loading />
      </div>
    </div>
  </div>
</template>

<script>
import {UCheckbox, UTable, UTd, UTh, UTr, LocalStorage, UChip, uid} from 'uloc-vue'
import {EBtnTableOptions, ErpCheckbox, ErpSField, ErpInput, HelperInputBtn} from 'uloc-vue-plugin-erp'
import {
  listInscritos as list,
  deleteInscricao,
  enviarCampanhaInscricao,
  upgradeCampanha, find
} from '../../../domain/marketing/services'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from '@/components/leiloes/components/context/list-menu-lote'
import listOptions from '@/components/leiloes/windows/listOptions'
import DefaultMixin from '@/components/leiloes/mixin'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import TaskMenuOptionsItem from "components/sltarefas/components/tarefa/window/context/context-window-options-item";
import SlLoading from "components/layout/components/Loading.vue";

const listName = 'mkt.inscritos.list'
const listStorage = tableColumnsCache(listName, [
  {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'Contato', name: 'contato', active: true, sortable: true, ordering: 1},
  {label: 'Envios', name: 'envios', active: true, sortable: true, ordering: 1},
  {label: 'Lido', name: 'lido', active: true, sortable: true, ordering: 1},
  {label: 'Cliques', name: 'cliques', active: true, sortable: true, ordering: 1},
  {label: 'Desinscrito', name: 'desinscrito', active: true, sortable: true, ordering: 1},
], 4);

let filters = {
  enviados: false,
  naoEnviados: false,
  desinscritos: false,
  lidos: false,
  clicados: false
}

export default {
  name: 'MktInscritos',
  mixins: [DefaultMixin],
  inject: ['container'],
  props: {},
  data() {
    return {
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            required: o.required || null,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      previewFullActive: false,
      loadingPreview: true,
      enviando: []
    }
  },
  watch: {
    'table.filters.enviados'(v) {
      setTimeout(() => this.load(), 300)
    },
    'table.filters.naoEnviados'(v) {
      setTimeout(() => this.load(), 300)
    },
    'table.filters.desinscritos'(v) {
      setTimeout(() => this.load(), 300)
    },
    'table.filters.lidos'(v) {
      setTimeout(() => this.load(), 300)
    },
    'table.filters.clicados'(v) {
      setTimeout(() => this.load(), 300)
    }
  },
  components: {
    SlLoading,
    //TaskMenuOptionsItem,
    //MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    // UCheckbox,
    //EBtnTableOptions,
    // DefaultListMenu,
    ErpCheckbox,
    ErpSField,
    ErpInput,
    HelperInputBtn,
    ERow,
    ECol,
    UChip
  },
  mounted() {
    this.load()
  },
  computed: {
    campanha() {
      return this.container.campanha
    },
    visibledColumns() {
      return this.listStorage.filter(item => {
        /*if (item.name === 'imagem') {
          return !this.table.filters.ocultarFotos
        }*/
        return item.active
      }).map(item => item.name)
    },
    filteredServerData() {
      let data = this.table.serverData
      return data
    },
    srcPreview () {
      return this.getUrl()
    }
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {
      this.table.loading = true
      this.table.selected = []
      const extraFilters = []
      if (this.table.filters.pesquisa) {
        extraFilters.push('search=' + this.table.filters.pesquisa)
      }
      if (this.table.filters.enviados || this.table.filters.naoEnviados || this.table.filters.desinscritos || this.table.filters.lidos || this.table.filters.clicados) {
        extraFilters.push('enviados=' + (this.table.filters.enviados ? 1 : 0))
        extraFilters.push('naoEnviados=' + (this.table.filters.naoEnviados ? 1 : 0))
        extraFilters.push('desinscritos=' + (this.table.filters.desinscritos ? 1 : 0))
        extraFilters.push('lidos=' + (this.table.filters.lidos ? 1 : 0))
        extraFilters.push('clicados=' + (this.table.filters.clicados ? 1 : 0))
      }
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&${extraFilters.join('&')}`)

      list(this.campanha.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result || []

            this.table.loading = false
            this.loadedData()
          })
          .catch(error => {
            this.loading = false
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    loadedData() {
      this.$emit('loaded', this.table.serverData)
    },
    enviarCampanhaInscrito(inscricao) {
      const m = inscricao.mensagens ? inscricao.mensagens.map(m => m.destinatario).join(', ') : '-'
      this.$uloc.notify({color: 'info', position: 'bottom', message: 'Enviando campanha para ' + m})
      this.enviando.push(inscricao.id)
      enviarCampanhaInscricao(this.campanha.id, inscricao.id)
          .then(response => {
            this.$uloc.loading.hide()
            this.load()
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Envio OK para ' + m})
            this.enviando.splice(this.enviando.indexOf(inscricao.id), 1)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
            this.enviando.splice(this.enviando.indexOf(inscricao.id), 1)
          })
    },
    remover(inscricao) {
      this.$uloc.dialog({
        title: 'Remover inscrito de campanha',
        message: `Tem certeza que deseja remover a inscrição de ${inscricao.pessoa.name} desta campanha ?`,
        noCaps: true,
        ok: `Sim, remover`,
        cancel: 'Não'
      })
          .then(() => {
            deleteInscricao(this.campanha.id, inscricao.id)
                .then(response => {
                  this.load()
                  this.dg('Inscrição removida.')
                })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {

          })
    },
    preview(inscricao) {
      console.log(inscricao)
    },
    previewFull () {
      this.previewFullActive = !this.previewFullActive
      this.$nextTick(() => {
        if (this.previewFullActive) {
          this.previewLoader()
        }
      })
    },
    getUrl () {
      return `${process.env.VUE_APP_API}/api/public/mkt/campanhas/${this.campanha.id}/preview?uid=${uid()}`
    },
    previewLoader () {
      if (this.campanha) {
        this.loadingPreview = true
        find(this.campanha.id, true)
            .then(response => {
              this.loadingPreview = false
              this.$refs.frame.src = this.getUrl()
            })
            .catch(error => {
              this.loadingPreview = false
              this.alertApiError(error)
            })
      }
    }
  }
}
</script>
