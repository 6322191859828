<script>
import {UProgress} from "uloc-vue"
import SlLoading from "components/layout/components/Loading"
import {find, upgradeCampanha} from "@/domain/marketing/services"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
import CampanhaHelper from "components/marketing/components/campanhaHelper"
import SlCheckbox from "@/reuse/input/Checkbox"
import Documento from "components/documentos/components/Documento"
import AcaoMkt from "components/marketing/components/AcaoMkt"
import MktInscritos from "components/marketing/components/Inscritos"

export default {
  name: 'MarketingCampanha',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [CampanhaHelper],
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  directives: {},
  components: {
    AcaoMkt,
    SlCheckbox,
    SlLoading,
    ErpSField,
    ECol,
    ERow,
    Documento,
    MktInscritos,
    UProgress
  },
  data() {
    return {
      loading: true,
      campanha: null,
      mounting: true,
      limits: {}
    }
  },
  watch: {
    'campanha.active' (v) {
      !this.mounting && this.$nextTick(() => {
        this.updatePublicacaoCampanha()
      })
    }
  },
  computed: {
    progressoCampanha () {
      const inscritos = Number(this.campanha.cacheInscritos)
      const enviados = Number(this.campanha.cacheEnviados)
      const total = ((enviados / inscritos) * 100).toFixed(2)
      return total > 100 ? 100 : total
    }
  },
  mounted() {
    this.erplayout.erpheader.menu.setActiveMenu('marketing')
    this.load(this.$route.params.id)
  },
  beforeDestroy() {
    this.$timeout && clearTimeout(this.$timeout)
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load(id = null) {
      this.loading = true
      find(id || this.$route.params.id)
          .then(response => {
            this.campanha = response.data
            this.limits = response.data.limits
            this.loading = false
            this.$nextTick(() => {
              if (this.mounting) {
                this.mounting = false
              }
              if (this.campanha.status === 1) {
                this.$timeout = setTimeout(() => {
                  this.load(this.$route.params.id)
                }, 10000)
              }
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    abrirDoc(doc, e) {
      this.$refs.docApp.show(doc.id)
    },
    updatePublicacaoCampanha () {
      upgradeCampanha(this.campanha.id, {
        active: this.campanha.active
      })
          .then(response => {
            this.load(this.campanha.id)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Campanha - Marketing',
    name: 'Campanha - Marketing'
  }
}
</script>

<template>
  <div class="full-height" v-if="!loading">
    <transition name="fade" appear>
      <div class="transition-generic w-full wrapper-lg">
        <div class="wrapper bg-grey-4 b-radius-3px font-16">
          Limite de uso: <strong>{{limits.usedValue}}/{{limits.limit}}</strong> por hora. <span v-if="limits.expires && limits.expires.date" style="font-size: 10px">Renovação do limite: {{limits.expires|formatDateDistance}}</span>
        </div>
        <p class=""><a class="font-12 text-blue-grey" @click="$router.push({name: 'marketing.main'})">Voltar</a></p>
        <e-row>
          <e-col>
            <h2 class="">{{ campanha.nome }}</h2>
          </e-col>
          <e-col class="text-right">
            <acao-mkt @update="load" />
          </e-col>
        </e-row>

        <div v-if="campanha.status === 1" class="wrapper-sm bg-yellow-1 b b-radius-3px m-b m-t">
          <e-row class="m-b-n-sm">
            <e-col class="flex items-center">
              <sl-loading content="" class="size-18 m-r" /> Preparando a campanha, por favor, aguarde...
            </e-col>
          </e-row>
        </div>

        <e-row mr>
          <e-col class="bg-white b b-radius-3px">
            <div class="wrapper bg-white b b-radius-3px">
              <e-row mr>
                <e-col style="max-width: 100px">
                  <erp-s-field label="Publicado?">
                    <sl-checkbox v-model="campanha.active"/>
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 140px">
                  <erp-s-field label="Iniciar em">
                    <div class="font-bold">{{ campanha.dataInicio|formatDate }}</div>
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 140px">
                  <erp-s-field label="Data de término">
                    <div v-if="campanha.dataTermino" class="font-bold">{{ campanha.dataTermino|formatDate }}</div>
                    <div v-else>-</div>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field label="Assunto">
                    <div class="font-bold">{{ campanha.assunto }}</div>
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 200px">
                  <erp-s-field view="tr" label="Enviar para">
                    <div class="font-bold text-right">{{ getTipoLabel(campanha.tipoString) }}</div>
                  </erp-s-field>
                </e-col>
<!--                <e-col>
                  <erp-s-field label="Layout">
                    <div v-if="campanha.templateRelacionado" class="font-bold">{{ campanha.templateRelacionado.id }} -
                      {{ campanha.templateRelacionado.name }} <small><a @click="abrirDoc(campanha.templateRelacionado)">pré-visualizar</a></small>
                    </div>
                  </erp-s-field>
                </e-col>-->
              </e-row>
              <e-row class="m-t" mr>
                <e-col>
                  <erp-s-field label="Remetente">
                    <div v-if="campanha.remetente && campanha.remetente.nome">{{ campanha.remetente.nome }} ({{ campanha.remetente.remetente }}) <span class="font-thin">[<span class="capitalize font-10">{{ campanha.remetente.canal }}</span>]</span></div>
                    <div v-else>-</div>
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 140px">
                  <erp-s-field view="tr" label="Status">
                    <div class="font-bold flex items-center justify-end no-wrap" :class="'mkt-status-' + campanha.status"><i v-if="campanha.status === 3" class="fa fa-spin fa-spinner m-r-sm"></i> {{ campanha.statusString }}</div>
                  </erp-s-field>
                </e-col>
              </e-row>
            </div>
          </e-col>
          <e-col class="bg-white b b-radius-3px" style="max-width: 200px">
            <div class="wrapper">
              <e-row mr>
                <e-col>
                  <div class="flex items-center no-wrap">
                    <u-progress class="progress-sgrp m-r"
                                :percentage="Number(progressoCampanha)"
                                color="positive" height="6px" style="border-radius: 5px; max-width: 100%"></u-progress>
                    {{progressoCampanha}}%
                  </div>
                </e-col>
              </e-row>
              <e-row mr>
                <e-col>
                  <erp-s-field label="Inscritos">
                    {{ campanha.cacheInscritos|number }}
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field label="Enviados">
                    {{ campanha.cacheEnviados|number }}
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row class="m-t" mr>
                <e-col>
                  <erp-s-field label="Abertos">
                    {{ campanha.cacheAbertos|number }}
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field label="Cliques">
                    {{ campanha.cacheCliques|number }}
                  </erp-s-field>
                </e-col>
              </e-row>
            </div>
          </e-col>
        </e-row>

        <mkt-inscritos />
        <div class="docs-main">
          <documento ref="docApp"/>
        </div>
      </div>
    </transition>
  </div>
  <div class="flex flex-center full-height m-t-xxl" v-else>
    <sl-loading/>
  </div>
</template>

